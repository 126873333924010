import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AboutImage, CopyContainer, LearnLine } from "./styles/webinarStyles";
import Speakers from "./Speakers";
import { Converter } from "showdown";

const WebinarAboutSection = ({ copy, learnLines, speakers, image = "" }) => {
  const converter = new Converter();
  return (
    <SectionContainer>
      <SectionInner style={{ gap: "5rem" }}>
        <SectionCol className="col" style={{ alignItems: "flex-start" }}>
          <TextContainer style={{ gap: "1.25rem" }}>
            <FancyTitleMed style={{ textAlign: "left" }}>
              About the Webinar
            </FancyTitleMed>
            <CopyContainer
              dangerouslySetInnerHTML={{ __html: copy }}
            ></CopyContainer>
          </TextContainer>
          {learnLines && (
            <TextContainer>
              <TitleSmall>What You'll Learn in 45 Minutes</TitleSmall>
              <ul style={{ padding: 0 }}>
                {learnLines.map(item => {
                  const html = converter.makeHtml(item);
                  return (
                    <LearnLine>
                      <StaticImage
                        src="./assets/check.png"
                        alt="check icon"
                        placeholder="blurred"
                        objectFit="contain"
                        style={{
                          width: "20px",
                          aspectRatio: "1",
                          flexShrink: 0,
                        }}
                        quality={100}
                      />
                      <div dangerouslySetInnerHTML={{ __html: html }}></div>
                    </LearnLine>
                  );
                })}
              </ul>
            </TextContainer>
          )}
          {!!image && <Speakers speakers={speakers} />}
        </SectionCol>
        <SectionCol
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-end",
          }}
        >
          {!!image ? (
            <AboutImage src={image} alt="About" />
          ) : (
            <Speakers speakers={speakers} />
          )}
        </SectionCol>
      </SectionInner>
    </SectionContainer>
  );
};

export default WebinarAboutSection;
